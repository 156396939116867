






































































import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    currency: {
      type: String,
      required: false,
      default: "PLN",
    },
    participantServices: {
      type: Array,
      required: false,
      default: () => [],
    },
    participantFees: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    participantAccommodation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    totalLeftToPay: {
      type: String,
      required: false,
      default: "",
    },
    toPay: {
      type: String,
      required: false,
      default: "",
    },
    paid: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { root, emit }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      loaded: false,
      registrationPayments: props.participantFees,
      servicesPayments: props.participantServices,
      accommodationPayments: props.participantAccommodation,
    });

    const model = reactive({
      registration: state.registrationPayments,
      services: state.servicesPayments,
      accommodation: state.accommodationPayments,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.participantAlreadyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        registrationPayment: (({ id, toPay, paid, paymentDate }) => ({
          id,
          toPay,
          paid,
          paymentDate,
        }))(model.registration),
        additionalServicesPayments: model.services.map((el: any) => ({
          id: el.id,
          toPay: el.toPay,
          paid: el.paid,
          paymentDate: el.paymentDate,
        })),
        accommodationPayment: model.accommodation
          ? {
              id: model.accommodation?.id,
              toPay: model.accommodation?.toPay,
              paid: model.accommodation?.paid,
              paymentDate: model.accommodation?.paymentDate,
            }
          : null,
      };

      state.loading = true;

      axiosInstance
        .put(`participant/${root.$route.params.ptid}/payments`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "event.participant.editInfo.participantUpdateSuccessfull"
            ),
          });
          emit("fetch");
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      model,
      onSubmit,
    };
  },
});
